<template>
  <TreeSelectWithFilter
    v-if="typografedReasons.length"
    v-model="reasons"
    :firstLevel.sync="firstLevel"
    :items="typografedReasons"
  />
</template>

<script>
import TreeSelectWithFilter from './controlTypes/TreeSelectWithFilter'
import { mapMutations, mapActions, mapGetters } from 'vuex'

export default {
  components: {
    TreeSelectWithFilter,
  },
  methods: {
    ...mapMutations({
      setFirstLevel: 'ly/SET_FIRST_LEVEL',
    }),
    ...mapActions({
      setParams: 'ly2/setFiltersAndLoadData',
    }),
  },
  computed: {
    ...mapGetters({
      getDictionaries: 'ly2/getDictionaries',
      getFirstLevel: 'ly/getFirstLevel',
      getParams: 'ly2/getFilters',
    }),
    /*
      Типографит текст причины
      Ищет пробел затем  1 или 2 символа и пробел — заменяет последний пробел на неразрывный
    */
    typografedReasons() {
      return this.getDictionaries.reasons.map(reason => ({
        ...reason,
        name: reason.name.replaceAll(/(\s\S{1,2})\s/g, '$1\xa0'),
      }))
    },
    firstLevel: {
      get() {
        return this.getFirstLevel
      },
      set(firstLevel) {
        this.setFirstLevel(firstLevel)
      },
    },
    reasons: {
      get() {
        return [...this.getParams['reasonIds']] || []
      },
      set(reasonIds) {
        this.setParams({ reasonIds })
      },
    },
  },
}
</script>
