<template>
  <v-container class="tree-view pa-0">
    <v-sheet class="pa-4 mb-4 grey">
      <v-text-field
        v-model="search"
        label="Фильтр причин"
        dark
        flat
        solo-inverted
        hide-details
        clearable
        clear-icon="mdi-close-circle-outline"
        class="flex-row"
      >
      </v-text-field>
      <div class="d-flex justify-space-between">
        <!-- <v-switch
          v-model="firstLevelLocal"
          @change="$emit('update:first-level', !firstLevel)"
          flat
          dark
          label="Классы причин смерти"
        ></v-switch> -->
        <v-checkbox dark label="Все" v-model="selectAll" @change="selectAllFunc"></v-checkbox>
      </div>
    </v-sheet>
    <v-treeview
      ref="tree"
      :items="reasonsTree"
      item-key="id"
      selectable
      selection-type="leaf"
      :search="search"
      :value="value"
      :key="firstLevel"
      open-all
      dense
      @input="onInput"
    ></v-treeview>
  </v-container>
</template>

<script>
export default {
  name: 'TreeView',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
    },
    firstLevel: {
      type: Boolean,
    },
  },
  mounted() {
    this.firstLevelLocal = this.firstLevel
  },
  data: () => ({
    search: '',
    firstLevelLocal: true,
    selectAll: null,
    open: [],
    allOpened: false,
    lastOpen: [],
  }),
  watch: {
    isAllSelected: {
      handler() {
        this.selectAll = this.isAllSelected
      },
      immediate: true,
    },
    reasonsTree: {
      handler() {
        if (this.calculatedValue.length !== this.value.length)
          this.$emit('input', this.calculatedValue)
      },
      immediate: true,
    },
    firstLevel() {
      this.$nextTick(() => {
        this.allOpened = true
        this.$refs.tree.updateAll(true)
      })
    },
  },
  computed: {
    reasonsTree() {
      return this.buildReasonsTree(this.items, this.firstLevel ? 2 : 0)
    },
    calculatedValue() {
      return this.calculateValue(this.items, this.firstLevel ? 2 : 0)
    },
    isAllSelected() {
      const [a1, a2] = [[], this.value]
      this.f(this.reasonsTree, a1)
      if (a1.length === a2.length && JSON.stringify(a1.sort()) === JSON.stringify(a2.sort())) {
        return true
      }
      return false
    },
  },
  methods: {
    // todo понять что делает, поменять название
    f(a, s) {
      if (!a) return
      a.forEach(d => {
        if (d.isLeaf) s.push(d.id)
        else this.f(d.children, s)
      })
    },
    onInput(e) {
      if (
        this.value.length !== e.length &&
        JSON.stringify(e.sort()) !== JSON.stringify([...this.value].sort())
      ) {
        this.$emit('input', e)
      }
    },
    selectAllFunc() {
      if (this.selectAll) {
        const s = []
        this.f(this.reasonsTree, s)
        this.$emit('input', s)
      } else {
        this.$emit('input', [])
      }
    },
    buildReasonsTree(data, maxLevel = 0) {
      if (!data || data.length === 0) return []
      if (maxLevel > 1)
        data = data
          .filter(d => d.level < maxLevel)
          .map(d => ({ ...d, isLeaf: maxLevel - d.level === 1 ? 1 : 0 }))
      let newData = []
      data.forEach(d => {
        if (!d.isLeaf) {
          if (d.parentId) {
            newData.push({
              ...d,
              id: `p${maxLevel}-${d.id}`,
              parentId: `p${maxLevel}-${d.parentId}`,
              name: `[${d.name}]`,
            })
          } else {
            newData.push({
              ...d,
              id: `p${maxLevel}-${d.id}`,
              name: `[${d.name}]`,
            })
          }
          newData.push({
            ...d,
            parentId: `p${maxLevel}-${d.id}`,
            level: d.level + 1,
            // тут хорошо бы писать нормально true false
            isLeaf: 1,
          })
        } else {
          newData.push({ ...d, parentId: `p${maxLevel}-${d.parentId}` })
        }
      })
      data = newData
      let hashTable = {}
      data.forEach(
        aData =>
          (hashTable[aData.id] = {
            ...aData,
            children: [],
          })
      )
      let dataTree = []
      data.forEach(aData => {
        if (aData.parentId) hashTable[aData.parentId].children.push(hashTable[aData.id])
        else {
          dataTree.push(hashTable[aData.id])
        }
      })
      return dataTree[0].children
    },
    // calculateValue возвращает массив?
    calculateValue(data, maxLevel = 0) {
      if (!data || data.length === 0) return []
      // кажется не обязательно. Используется один раз. Фильтр уже возвращает новый массив
      const localValue = this.value
      if (maxLevel > 1)
        data = data
          .filter(d => d.level < maxLevel)
          .map(d => ({ ...d, isLeaf: maxLevel - d.level === 1 ? 1 : 0 }))
      return localValue.filter(d => data.find(b => b.id === d))
    },
  },
}
</script>

<style scoped>
.tree-view >>> .v-treeview-node__label {
  white-space: normal;
}
.tree-view >>> .v-treeview-node__root {
  align-items: flex-start;
  min-height: 0;
  margin-bottom: 12px;
}
</style>
